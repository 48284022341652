import "./person-tile.scss";

import React from "react";

const PersonTile = ({ fullName, tel, email }) => {
   return (
      <div className="person-tile">
         <div className="person-tile__name-container">
            <div className="person-tile__logo-container">
               <img
                  src={require("assets/icons/heading-shape.svg").default}
                  className="img-fluid"
                  alt="Ikona"
               />
            </div>
            <div className="person-tile__name">{fullName}</div>
         </div>
         <div className="person-tile__bottom-container">
            <div className="person-tile__contact-link">
               <img
                  src={require("assets/icons/phone.svg").default}
                  alt="Telefon"
                  className="person-tile__contact-icon"
               />
               <a href={`tel:+48${tel}`} className="person-tile__link">
                  {tel}
               </a>
            </div>
            <div className="person-tile__contact-link">
               <img
                  src={require("assets/icons/email.svg").default}
                  alt="Mail"
                  className="person-tile__contact-icon"
               />
               <a href={`mailto:${email}`} className="person-tile__link">
                  {email}
               </a>
            </div>
         </div>
      </div>
   );
};

export default PersonTile;
