import "styles/pages/page-offer.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import { Link } from "gatsby";
import PersonTile from "components/PersonTile";

const OfferPage = ({ pageContext }) => {
   const seo = pageContext.seo;
   const page = pageContext.data;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="offer-section">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row justify-content-center">
                  <div className="col-xl-10">
                     <div
                        className="offer-section__content"
                        dangerouslySetInnerHTML={{
                           __html: pageContext.content,
                        }}
                     />
                     <div className="row offer-section__bottom-row">
                        {page.offers.map((item, index) => (
                           <div className="col-lg-6" key={index}>
                              <div className="offer-section__person-container">
                                 <Link
                                    to={item.btnLink?.mediaItemUrl}
                                    className="button"
                                 >
                                    {item.btnText}
                                 </Link>
                                 <div className="offer-section__person-text">
                                    {item.caption}
                                 </div>
                                 <PersonTile
                                    fullName={item.name}
                                    tel={item.phone}
                                    email={item.email}
                                 />
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </Layout>
   );
};

export default OfferPage;
